@tailwind base;
@tailwind components;
@tailwind utilities;

/* Write your own custom base styles here */
@layer base {
  :root {
    --color-primary: 0, 162, 255;
    --color-primary2: 3, 105, 161;
    --color-purple: 139, 214, 255;
    --color-red: 208, 20, 24;
    --color-red-secondary: 253, 84, 115;
    --color-tints: 19, 78, 71;
    --color-cerulean: 0, 149, 231;
    --color-salmon: 255, 123, 123;
    --color-tree: 111, 180, 142;
    --color-yellow: 0, 162, 255;
    --color-yellow-two: 188, 89, 230;
    --color-yellow-three: 255, 195, 60;
    --color-carnation: 0, 162, 255;
    --color-text-base: 7, 0, 45;
    --color-text-inverted: 255, 255, 255;
    --color-text-muted: 89, 89, 89;
    --color-text-secondary: 139, 214, 255;
    --color-text-countdown: 20, 113, 95;
    --color-fill: 255, 255, 255;
    --color-fill-thumbnail: 243, 246, 250;
    --color-fill-one: 241, 246, 249;
    --color-fill-two: 243, 246, 249;
    --color-fill-three: 242, 242, 242;
    --color-fill-six: 35, 43, 53;
    --color-fill-eight: 8, 34, 60;
    --color-fill-secondary: 139, 214, 255;
    --color-fill-dropdown-hover: 246, 249, 252;
    --color-border-primary: 0, 162, 255;
    --color-border-base: 231, 236, 240;
    --color-border-one: 227, 232, 236;
    --color-border-two: 226, 232, 240;
    --color-border-three: 230, 230, 230;
    --color-border-four: 222, 229, 234;
    --color-button-secondary: 243, 245, 249;
    --color-button-disable: 238, 238, 238;
    --color-button-hover: 229, 234, 241;
    --color-highlighted: 139, 214, 255;
    --color-footer: 7, 0, 45;
    --box-shadow-card: '0px 0px 6px rgba(79, 95, 120, 0.1)';
    --box-shadow-card-hover: '0px 0px 6px rgba(79, 95, 120, 0.2)';
    --box-shadow-counter: '0px 4px 20px rgba(79, 95, 120, 0.15)';
  }

  html {
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body {
    @apply text-skin-base font-body antialiased;
  }
  p {
    @apply mb-5;
  }
  p:last-of-type {
    @apply mb-0;
  }
  p > strong {
    @apply font-semibold;
  }
  p > a {
    @apply text-skin-primary transition;
  }
  p > a:hover {
    @apply text-skin-base;
  }
  .h-inherit {
    height: auto;
    height: inherit;
  }
  .order-list-enable ol {
    list-style: decimal;
    @apply ps-4 lg:ps-8 space-y-1.5;
  }
  button:focus {
    outline: 0px solid !important;
  }
}

@layer components {
  /* Header Menu */
  #siteHeader.is-scrolling .innerSticky {
    @apply shadow-header top-0 transition duration-200 ease-in-out;
  }

  #siteHeader.header-four.is-scrolling .innerSticky {
    @apply lg:pb-1.5;
  }

  .headerMenu .menuItem > a::before {
    @apply absolute end-0 w-0 bg-skin-primary;
    content: '';
    height: 3px;
    transition: all 0.3s ease-in-out 0s;
    bottom: -12px;
  }

  .subMenu {
    visibility: hidden;
    transition: all 0.4s;
    top: calc(100% + 21px);
  }

  /* for header two */
  .header-one .headerMenu .menuItem > a::before {
    bottom: -22px;
  }

  .subMenu {
    width: 220px;
  }

  @media (min-width: 1280px) {
    .subMenu {
      width: 240px;
    }
  }

  .subMenuChild {
    transition: all 0.3s;
  }

  .subMenu li:hover > .subMenuChild {
    @apply opacity-100 visible -top-3;
  }

  .headerMenu .menuItem.activeMenu > a::before {
    @apply w-full end-auto start-0;
  }

  .headerMenu .menuItem.activeMenu > .subMenu {
    visibility: visible;
    top: calc(100% + 0px);
  }

  .header-one .headerMenu .menuItem.activeMenu > .subMenu {
    top: calc(100% + 10px);
  }

  /* Mobile Drawer Menu */
  .mobile-menu > li > div > a {
    @apply text-15px py-2.5 font-medium;
  }
  .mobile-menu li li {
    @apply text-sm;
  }
  .mobile-menu li li div > a {
    @apply relative py-2.5 text-opacity-90;
  }
  .mobile-menu li > ul > li > div > a:before {
    content: '';
    height: 1px;
    @apply flex absolute top-1/2 start-1 md:start-3 w-[7px] bg-skin-base;
  }

  /* For login and signup switch */
  .switch {
    height: 22px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  /* For login and signup switch */
  .switch {
    height: 22px;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  main input:checked + .slider {
    background-color: #e5e9e8;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  /* Others Component */
  .after-item-opacity:after {
    @apply w-[5%] 2xl:w-[2%] 3xl:w-[2%] 4xl:hidden;
  }
  @media (min-width: 1280px) {
    .after-item-opacity:after {
      @apply absolute end-0 top-0 h-full;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 1)
      );
      content: '';
      z-index: 5;
    }
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 21;
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    opacity: 0;
    top: 0;
    @apply start-0;
    transition: 0.35s ease-in-out;
  }

  .overlay.open,
  .overlay.input-focus-overlay-open,
  .overlay.open-search-overlay,
  .category-dropdown-menu > li:hover > div {
    opacity: 1;
    visibility: visible;
    transition:
      opacity 0.3s ease-out,
      all 0.1s ease-in-out;
  }

  .category-dropdown-menu > li > div {
    left: 85%;
  }
  .category-dropdown-menu > li:hover > div {
    left: 100%;
  }
  .category-dropdown-menu > li > div li > a {
    padding: 6px 0rem;
  }
  .bg-progress-striped {
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.3) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.3) 75%,
      transparent 75%,
      transparent
    );
    background-size: 14px 14px;
  }
}

@layer utilities {
  .scroll-snap-type-y-mandatory {
    scroll-snap-type: y mandatory;
  }
  .scroll-snap-align-start {
    scroll-snap-align: start;
  }
  @media (min-width: 1024px) {
    .minimal-main-content {
      width: calc(100% - 320px);
    }
  }
  @media (min-width: 1280px) {
    .minimal-main-content,
    .trendy-main-content {
      width: calc(100% - 420px);
    }
    .banner-main-content {
      width: calc(100% - 600px);
    }
    .popular-main-content {
      width: calc(100% - 300px);
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .hero-banner-six {
      background-position: 15% center;
    }
    .page-header-banner {
      background-position: 17% center;
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .hero-banner-six {
      background-position: 42% center;
    }
    .page-header-banner {
      background-position: 80% center;
    }
  }
  @media (min-width: 1280px) and (max-width: 1535px) {
    .hero-banner-six {
      background-position: 35% center;
    }
  }
}

/* Breadcrumb truncate last child */
.breadcrumb li:last-child {
  @apply truncate font-normal text-skin-base;
}
.cart-counter-badge {
  min-width: 20px;
  min-height: 20px;
  padding: 2px;
  border-radius: 20px;
  font-size: 10px;
}
.style-variant-white .breadcrumb ol > li > a > svg,
.style-variant-white .breadcrumb ol > li > a {
  @apply text-skin-inverted text-opacity-70;
}
.style-variant-white .breadcrumb ol > li > svg {
  @apply text-skin-inverted text-opacity-50;
}
.style-variant-white .breadcrumb ol > li:last-of-type > a {
  @apply text-skin-inverted;
}
.shadow-md {
  box-shadow: 1px 2px 3px rgb(0 0 0 / 25%);
}
.font-light {
  font-weight: 300;
}
.category-dropdown-menu > li:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #ececec;
}
@media (max-wdith: 1279px) {
  .cart-counter-badge {
    min-width: 16px;
    min-height: 16px;
  }
}
.header-two.sticky-header.is-scrolling .navbar-logo,
.header-five.sticky-header.is-scrolling .navbar-logo {
  @apply -mt-1;
}

.header-five.sticky-header.is-scrolling .innerSticky .categories-header-button,
.header-four.sticky-header.is-scrolling .innerSticky .categories-header-button,
.header-three.sticky-header.is-scrolling
  .innerSticky
  .categories-header-button {
  display: none;
}

/* sticky site header styles */
@media (min-width: 1280px) {
  .sticky-header.is-scrolling .navbar {
    @apply fixed top-0 w-full shadow-header z-30;
  }

  .sticky-header.is-scrolling .delivery-address,
  .sticky-header .navbar .sticky-search {
    @apply hidden;
  }

  .sticky-header.is-scrolling .navbar .sticky-search {
    @apply flex;
  }

  .sticky-header .navbar-logo,
  .sticky-header .navbar .navbar-right {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  .sticky-header.is-scrolling .navbar-logo,
  .sticky-header.is-scrolling .navbar .navbar-right {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    @apply w-auto opacity-100 overflow-visible;
  }

  .header-two.sticky-header.is-scrolling .headerMenu,
  .header-five.sticky-header.is-scrolling .headerMenu {
    @apply ms-8;
  }
}
#siteHeader .innerSticky .top-bar-search {
  @apply hidden;
}
@media (max-width: 1024px) {
  .sticky-header .top-bar.active-mobile-search .logo {
    @apply hidden;
  }

  #siteHeader.active-mobile-search .innerSticky .top-bar-search {
    display: block;
  }
}

.subscribe-btn:hover {
  box-shadow: none !important;
}
.registration img {
  height: 100%;
  object-fit: cover;
}

/* Order page */
/* order list table */
.order-list-table table th,
.order-list-table table td {
  border: none;
}
.order-list-table.rc-table thead td,
.order-list-table.rc-table thead th {
  text-align: left;
}
.order-list-table .rc-table-content {
  border-color: transparent;
}
.order-list-table.rc-table .rc-table-thead {
  background: #f3f6f9;
  border-radius: 3px;
  opacity: 1;
}
.order-list-table.rc-table thead th {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}
.order-list-table.rc-table th,
.order-list-table.rc-table td {
  padding: 9px 20px;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
}
.order-list-table.rc-table thead th {
  padding-top: 12px;
  padding-bottom: 12px;
}
.order-list-table.rc-table td {
  border-bottom: 1px solid #e7ecf0;
}
.order-list-table.rc-table tr td {
  transition: background-color 0.5s;
}
.order-list-table.rc-table tr:hover td {
  background-color: #f3f6f9;
}
.bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.actions_button_group svg {
  width: 100%;
}
@media (max-width: 767px) {
  .order-list-table-wraper {
    position: relative;
    overflow-x: auto;
  }
  .order-list-table.rc-table th:not(.operations-cell),
  .order-list-table.rc-table td:not(.operations-cell) {
    white-space: nowrap;
  }
  .order-list-table.rc-table th:first-child,
  .order-list-table.rc-table td:first-child {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;
  }
  .order-list-table.rc-table th.id-cell {
    background: #f3f6f9;
  }
  .order-list-table.rc-table td.operations-cell {
    padding: 0;
  }
}

/* Address box */

.address__box input[type='radio']:checked ~ label {
  border-color: #02b290;
}

@media (min-width: 1024px) {
  .address__box:hover .address__actions {
    opacity: 1;
  }
}

/* Checkout details page accordion */
.accordion__panel.collapsed .accordion__content {
  display: none;
}
.accordion__panel.expanded .accordion__content {
  display: block;
  background-color: #ffffff;
}
.accordion__panel.collapsed .accordion__button {
  background-color: #f8f9fa;
}

.address__box:hover .address__actions {
  opacity: 1;
}
.borderColor::before {
  content: '';
  position: absolute;
  left: 2px;
  width: 3px;
  background: #02b290;
  @apply h-6 top-3 2xl:top-2.5;
}
.registration > div {
  height: 100%;
}

.StripeElement {
  box-sizing: border-box;
  height: 50px;
  padding: 15px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  -webkit-transition: border 150ms ease;
  transition: border 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.scrollbar {
  scrollbar-width: none;
  -webkit-scrollbar-width: none;
}
.scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.wishlist-card:last-child {
  border-bottom: none;
}
.order-search {
  @apply bg-white !important;
  @apply rounded-md !important;
}
.order-icon-color {
  color: rgba(0, 0, 0, 0.3);
  height: 100%;
}
.checkout .save-change-button {
  display: none;
}
@font-face {
  font-family: snowflakeSans;
  src: url(../../public/fonts/SnowflakeSans-Medium.woff2);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: snowflakeSans;
  src: url(../../public/fonts/SnowflakeSans-Bold.woff2);
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
html,
body {
  font-family: 'snowflakeSans', sans-serif;
}
.content-pages .swiper-slide-active .bg-cover {
  min-height: auto !important;
  padding-bottom: 4rem !important;
}
.content-pages table {
  margin-bottom: 30px;
}
.content-pages table td b {
  font-size: 150%;
}
.content-pages table td {
  border: 1px solid;
  padding: 10px;
}
.content-pages .product-card {
  --tw-gradient-from: #0ea5e9 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  border-radius: 0.25rem;
  padding: 1rem 1rem 0;
}
.content-pages .grid-container {
  margin-bottom: 40px;
}
.content-card img {
  width: 100%;
  height: revert-layer;
}

.content h1 {
  font-size: 3rem;
  line-height: 2.75rem;
  color: black;
}

.content h2 {
  font-size: 2.5rem;
  line-height: 2.75rem;
  color: black;
}

.content h3 {
  font-size: 2rem;
  line-height: 2.75rem;
  color: black;
}

.content h4 {
  font-size: 1.75rem;
  line-height: 2.75rem;
  color: black;
}

.content h5 {
  font-size: 1.5rem;
  line-height: 2rem;
  color: black;
}

.content h6 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: black;
}

.content ul {
  list-style: disc;
  padding: 5px;
  margin: 15px;
}

.content ol {
  list-style: decimal;
  padding: 5px;
  margin: 15px;
}
