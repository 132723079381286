/*Custom Theme*/
.block-title {
  border-bottom: 2px solid #ebebeb;
  padding: 0 0 12px 0;
  position: relative;
  z-index: 10;
  text-transform: capitalize;
}

.block-title:before {
  content: '';
  background-color: #00a2ff;
  width: 120px;
  height: 2px;
  position: absolute;
  z-index: 2;
  bottom: -2px;
  display: inline-block;
}

.supper-category--list {
  .block-title {
    border: none;
  }
}

.carouselWrapper {
  .swiper-slide:last-child {
    .group {
      border: none;
    }
  }
}

span.has-dot:before {
  content: '';
  width: 4px;
  height: 4px;
  background: #d2d2d2;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  display: block;
  left: -2px;
}

/* pagination */
.rc-pagination.blog-pagination .rc-pagination-prev,
.rc-pagination.blog-pagination .rc-pagination-next,
.rc-pagination.blog-pagination .rc-pagination-item {
  height: 40px;
  width: 40px;
  padding: 6px 5px;
  background-color: #f2f3f4;
  border-radius: 40px;
  border: none;
  font-weight: 600;
  margin-right: 10px;

  &:hover {
    @apply bg-skin-primary;

    a {
      color: white;
    }
  }
}

.rc-pagination.blog-pagination li.rc-pagination-disabled {
  opacity: 0.5;
}

.rc-pagination.blog-pagination li.rc-pagination-item-active {
  @apply bg-skin-primary;
}

.rc-pagination.blog-pagination li svg {
  width: 100%;
}

.rc-pagination.blog-pagination li.rc-pagination-item-active a {
  opacity: 1;
  color: white;
}

.rc-pagination.blog-pagination li a {
  padding: 0;
  color: rgba(0, 0, 0, 0.6);
}

//images Icons
.bg-iconPhone {
  background: url('/assets/images/icons/icon-phone.png') no-repeat;
}

.bg-iconPhone2 {
  width: 44px;
  height: 44px;
  background-color: #ff9300;
  mask-image: url('/assets/images/icons/hotline_icon.svg');
}

//Mega Menu horizontal & Vertical
.subMega {
  width: 100%;
  visibility: hidden;
  transition: all 0.2s;
  top: calc(100% + 8px);
}

.headerMenu .menuItem.activeMenu>.subMega {
  visibility: visible;
  top: calc(100% + 0px);
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.category-dropdown-noborder {
  .border-2 {
    border: none;
  }
}

.category-dropdown-menu li {

  div.subMenu--level1,
  div.subMenu--level2,
  div.subMenu--level3 {
    top: -12px;
  }

  >div.dropdownMenu {
    left: 85%;
  }
}

.category-dropdown-menu li:hover>div.dropdownMenu {
  left: 100%;
  opacity: 1;
  visibility: visible;
  transition:
    opacity 0.3s ease-out,
    all 0.1s ease-in-out;
}

//TOOLBAR LISTING TOP
.filters-panel {
  margin-bottom: 15px;

  .form-field {
    margin: 0;
  }

  .list-view {
    display: inline-block;

    .btn-gridview {
      border: none;
      float: left;
      padding: 0.5em;
    }

    button.btn {
      padding: 5px;
      float: left;
      width: 38px;
      height: 38px;
      border: 1px solid #dedede;
      justify-content: center;
      align-items: center;

      &.grid {
        margin-right: -1px;

        .icon-bar {
          width: 4px;
        }

        .icon-bar:after,
        .icon-bar:before {
          position: absolute;
          display: block;
          width: 4px;
          height: 4px;
          content: '';
          transition: all 0.15s ease;
          background: #dedede;
        }

        .icon-bar:after {
          left: -5px;
        }

        .icon-bar:before {
          right: -5px;
        }
      }

      .icon-bar {
        position: relative;
        top: -1px;
        width: 14px;
        height: 4px;
        margin: 1px auto 0;
        transition: all 0.15s ease;
        background: #e5e5e5;
      }

      /* Hover Grid view*/
      &.active {
        .icon-bar {
          background: #333;
        }

        .icon-bar:after,
        .icon-bar:before {
          background: #333;
        }
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

//Product Grid
.products-category {
  .product-card {
    @media (min-width: 801px) {
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: #f5f5f5;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 5;
      }
    }
  }
}

.product-card {
  @media (min-width: 801px) {
    &:before {
      content: '';
      height: calc(100% - 60px);
      width: 1px;
      background: #f5f5f5;
      display: block;
      position: absolute;
      top: 30px;
      right: 0;
      z-index: 5;
    }
  }

  .product-cart-button {
    position: absolute;
    width: 100%;
    bottom: -40px;
    left: 0;
    z-index: 12;
    transition: bottom 0.3s ease-in-out;

    .button--mutiqty {
      max-width: 150px;
      margin: 0 auto;
    }
  }

  .card-img-container {
    transition: all 0.2s ease-in-out 0s;
  }

  .buttons--quickview {
    display: block;
    position: absolute;
    z-index: 5;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) scale(0);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 48px;
    opacity: 0;
    text-align: center;
    box-shadow: 0 3px 6px 1px rgb(0 0 0 / 20%);
    transition: all 0.3s ease-in-out 0s;
  }

  //Hover Product card
  &:hover {
    .product-cart-button {
      bottom: 5px;
    }

    .buttons--quickview {
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) scale(1);
    }
  }
}

.product-card-v2 {
  .product-cart-button {
    position: absolute;
    width: 100%;
    bottom: -40px;
    left: 0;
    z-index: 12;
    transition: bottom 0.3s ease-in-out;

    .button--mutiqty {
      max-width: 150px;
      margin: 0 auto;
    }
  }

  .card-img-container {
    transition: all 0.2s ease-in-out 0s;
  }

  .buttons--quickview {
    display: block;
    position: absolute;
    z-index: 5;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) scale(0);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 48px;
    opacity: 0;
    text-align: center;
    box-shadow: 0 3px 6px 1px rgb(0 0 0 / 20%);
    transition: all 0.3s ease-in-out 0s;
  }

  //Hover Product card
  &:hover {
    .product-cart-button {
      bottom: 5px;
    }

    .buttons--quickview {
      opacity: 1;
      visibility: visible;
      transform: translateX(-50%) scale(1);
    }
  }
}

.card-image--jump {

  //Hover Product card
  &:hover {
    .card-img-container {
      transform: translateY(-10px);
      opacity: 0.8;
    }
  }
}

.card-img-container,
.card-image--jump {
  img {
    margin: 0 auto;
  }
}

//Extraslider--sidebar
.extraslider--sidebar {

  .swiper-prev,
  .swiper-next {
    width: 24px;
    height: 24px;
    box-shadow: 0 1px 3px rgb(115 125 144 / 25%);

    svg {
      width: 15px;
    }
  }

  .product-card:before {
    display: none;
  }
}

.carouselTestimonial {
  .swiper .swiper-pagination-bullets {
    bottom: 8px;
  }
}

/*Layout-Header*/
.header-three {
  .cart-button {
    border: 2px solid rgba(255, 255, 255, 1);
  }
}

.header-four {
  .cart-button {
    border: none;

    svg {
      width: 28px;
      height: 28px;
    }

    +span {
      display: none;
    }

    .cart-counter-badge {
      right: 0;
    }
  }

  .accountButton {
    svg {
      width: 32px;
      height: 32px;
    }
  }
}

/*Layout-Footer*/
.footer-one {
  h3 {
    color: white;
  }
}

.footer-seven {
  h3 {
    color: white;
  }

  .form-subscribe:before {
    background: rgba(255, 255, 255, 0.1);
  }
}

.form-subscribe {
  margin: 40px 0 0px;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    background: #e1e1e1;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
  }
}

.footer-eight {
  h3 {
    color: white;
  }
}

.newsletterFooter8 {
  .form-subscribe {
    margin-bottom: 65px;

    &:before {
      display: none;
    }
  }
}

/*Home page 4*/

/*RTL Language - Arabic*/
[dir='rtl'] {
  .category-dropdown-menu li>div.dropdownMenu {
    right: 85%;
    left: auto;
  }

  .category-dropdown-menu li:hover>div.dropdownMenu {
    right: 100%;
    left: auto;
  }

  .shadow-md {
    box-shadow: -1px 2px 3px rgb(0 0 0 / 25%);
  }

  .after-item-opacity:after {
    right: 0;
    left: auto;
  }

  span.has-dot:before {
    left: auto;
    right: -2px;
  }
}

/*Responsive For Ipad */
@media (min-width: 768px) and (max-width: 1023px) {
  .page-type-home2 {
    .staticBanner--slider {
      .grid-cols-1 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }

  .page-type-home4 {
    .staticBanner--slider {
      .grid-cols-1 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }
}